.subscription-section-products {
  display: block;
  position: relative;

  .atp-dynamic-form__form{

  }

  .atp-dynamic-form__content {
    padding-top: 24px;
    height: inherit;
  }
}
.atp-dynamic-form__container{
  height: initial;
}

@import "./sections-tree/sections-tree.component.scss";
